<template lang="pug">
v-container()
  FwdHeadingLarge purchasing settings
  v-layout.mt-5(row wrap)
    v-flex(xs6).pr-3
      VendorCompanys
    v-flex(xs6).pl-3
    
  v-layout.mt-5(row wrap)
    v-flex(xs6).pr-3
    v-flex(xs6).pl-3
</template>

<script>
import { list as VendorCompanys } from '@/modules/vendors'

export default {
  components: {
    VendorCompanys
  }
}
</script>
